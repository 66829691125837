/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogContentText from '@mui/material/DialogContentText';
import { useVehiclesStateValue } from '../../../contexts/vehiclesStateContext';
import { useCompany } from '../../../hooks/company';
import { useRepository } from '../../../hooks/api';
import { useSnackbar } from '../../../hooks/snackbar';
import FleetButton from '../../../components/FleetButton';
import { getLicensePlateLabel } from '../../../shared/labels';

export default function VehicleDialog({ onClose, onVehiclesRefresh }) {
  const company = useCompany();
  const repository = useRepository();
  const openSnackbar = useSnackbar();

  const [state] = useVehiclesStateValue();

  const [vehicleRegistrationCountry, setVehicleRegistrationCountry] =
    useState('');
  const [vehicleRegistrationNumber, setVehicleRegistrationNumber] =
    useState('');
  const [vehicleVinNumber, setVehicleVinNumber] = useState('');
  const [huCategory, setHuCategory] = useState('');
  const [siCategory, setSiCategory] = useState('');
  const [roCategory, setRoCategory] = useState('');
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);

  useEffect(() => {
    if (state.vehicle) {
      const { vehicle } = state;
      setVehicleRegistrationCountry(vehicle.registrationCountry);
      setVehicleRegistrationNumber(vehicle.registrationNumber);
      if (!vehicle.isDynamic) {
        setVehicleVinNumber(vehicle.vinNumber);
        setHuCategory(vehicle.huCategory);
        setSiCategory(vehicle.siCategory);
        setRoCategory(vehicle.roCategory);
        setIsVehicleVisible(vehicle.isVehicleVisible);
      } else {
        setVehicleVinNumber('');
        setHuCategory('');
        setSiCategory('');
        setRoCategory('');
        setIsVehicleVisible(true);
      }
    } else {
      setVehicleRegistrationCountry('');
      setVehicleRegistrationNumber('');
      setVehicleVinNumber('');
      setHuCategory('');
      setSiCategory('');
      setRoCategory('');
      setIsVehicleVisible(true);
    }

    return () => {
      setVehicleRegistrationCountry('');
      setVehicleRegistrationNumber('');
      setVehicleVinNumber('');
      setHuCategory('');
      setSiCategory('');
      setRoCategory('');
      setIsVehicleVisible(true);
    };
  }, [state.vehicle]);

  const handleVehicleRegistrationCountryChange = (event) => {
    setVehicleRegistrationCountry(event.target.value);
  };

  const handleVehicleRegistrationNumberChange = (event) => {
    setVehicleRegistrationNumber(
      event.target.value.trim().replace(/\s/g, '').toUpperCase()
    );
  };

  const handleVehicleVinNumberChange = (event) => {
    setVehicleVinNumber(
      event.target.value.trim().replace(/\s/g, '').toUpperCase()
    );
  };

  const handleHuCategoryChange = (event) => {
    setHuCategory(event.target.value);
  };

  const handleSiCategoryChange = (event) => {
    setSiCategory(event.target.value);
  };

  const handleRoCategoryChange = (event) => {
    setRoCategory(event.target.value);
  };

  const handleIsVehicleVisibleChange = (event) => {
    setIsVehicleVisible(event.target.checked);
  };

  const handleSubmitButton = async () => {
    if (state.vehicle && !state.vehicle.isDynamic) {
      try {
        const response = await repository.company.updateVehicle(
          company.taxIdentificationHash,
          state.vehicle.id,
          {
            registration_country: vehicleRegistrationCountry,
            registration_number: vehicleRegistrationNumber,
            vin_number: vehicleVinNumber,
            hu_category: huCategory,
            si_category: siCategory,
            ro_category: roCategory,
            is_vehicle_visible: isVehicleVisible,
          }
        );

        onClose();
        await onVehiclesRefresh();

        const { status } = response;
        if (status === 200) {
          const { data } = response;
          const {
            fleetVehicle: { registrationCountry, registrationNumber },
          } = data;
          const licensePlateLabel = getLicensePlateLabel(
            registrationCountry,
            registrationNumber
          );
          openSnackbar(`Zaktualizowano pojazd ${licensePlateLabel}`, 'success');
        }
      } catch (e) {
        const { response } = e;
        if (response.status === 422) {
          openSnackbar(`Nie udało się zaktualizować pojazdu`, 'error');
        } else {
          openSnackbar(`Nie udało się zaktualizować pojazdu`, 'error');
        }
      }
    } else {
      try {
        const response = await repository.company.createVehicle(
          company.taxIdentificationHash,
          {
            registration_country: vehicleRegistrationCountry,
            registration_number: vehicleRegistrationNumber,
            vin_number: vehicleVinNumber,
            hu_category: huCategory,
            si_category: siCategory,
            ro_category: roCategory,
            is_vehicle_visible: isVehicleVisible,
          }
        );

        onClose();
        await onVehiclesRefresh();

        const { status } = response;
        if (status === 201) {
          const { data } = response;
          const {
            fleetVehicle: { registrationCountry, registrationNumber },
          } = data;
          const licensePlateLabel = getLicensePlateLabel(
            registrationCountry,
            registrationNumber
          );
          openSnackbar(`Dodano pojazd ${licensePlateLabel}`, 'success');
        }
      } catch (e) {
        const { response } = e;
        if (response.status === 422) {
          if (
            response.data.errors.includes(
              'Entity with the same tax identification number, registration number and registration country already exists'
            )
          ) {
            openSnackbar(`Pojazd o podanych danych już istnieje`, 'error');
          } else {
            openSnackbar(`Nie udało się dodać pojazdu`, 'error');
          }
        } else {
          openSnackbar(`Nie udało się dodać pojazdu`, 'error');
        }
      }
    }
  };

  const isSubmitButtonDisabled = useMemo(() => {
    if (
      !vehicleRegistrationCountry ||
      vehicleRegistrationCountry.length === 0
    ) {
      return true;
    }

    if (!vehicleRegistrationNumber || vehicleRegistrationNumber.length === 0) {
      return true;
    }

    if (!huCategory || huCategory.length === 0) {
      return true;
    }

    if (!siCategory || siCategory.length === 0) {
      return true;
    }

    return false;
  }, [
    vehicleRegistrationCountry,
    vehicleRegistrationNumber,
    huCategory,
    siCategory,
    roCategory,
  ]);

  return (
    <React.Fragment>
      <Dialog open={state.isVehicleDialogOpen} onClose={onClose}>
        <DialogTitle>
          {state.vehicle ? 'Edytuj pojazd' : 'Dodaj pojazd'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity='warning' sx={{ mb: 3 }}>
              Uwaga! Niepoprawne uzupełnienie danych pojazdu może skutkować
              wykupieniem niepoprawnej winiety, a w konsekwencji{' '}
              <strong>otrzymaniem mandatu</strong>. Jeśli nie mają Państwo
              pewności odnośnie poniższych parametrów, prosimy o kontakt na +48
              881 265 093, aby się upewnić.
            </Alert>
          </DialogContentText>
          <Stack spacing={2}>
            <FormControl required fullWidth variant='standard' size='small'>
              <InputLabel id='vehicle-registration-country-label'>
                Kraj rejestracji pojazdu
              </InputLabel>
              <Select
                labelId='vehicle-registration-country-label'
                id='vehicle-registration-country-select'
                label='Kraj rejestracji pojazdu'
                disabled={state.vehicle && state.vehicle.isDynamic}
                value={vehicleRegistrationCountry}
                onChange={handleVehicleRegistrationCountryChange}
              >
                <MenuItem value='Polska'>Polska</MenuItem>
                <MenuItem value='Austria'>Austria</MenuItem>
                <MenuItem value='Niemcy'>Niemcy</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              margin='dense'
              id='vehicle-registration-number-text-field'
              label='Numer rejestracyjny pojazdu'
              type='text'
              fullWidth
              variant='standard'
              size='small'
              disabled={state.vehicle && state.vehicle.isDynamic}
              value={vehicleRegistrationNumber}
              onChange={handleVehicleRegistrationNumberChange}
            />
            <FormControl required fullWidth variant='standard' size='small'>
              <InputLabel id='hu-category-label'>
                Kategoria pojazdu na Węgrzech
              </InputLabel>
              <Select
                labelId='hu-category-label'
                id='hu-category-select'
                label='Kategoria pojazdu na Węgrzech'
                value={huCategory}
                onChange={handleHuCategoryChange}
              >
                <MenuItem value='D1'>D1 (osobowe do 7 osób)</MenuItem>
                <MenuItem value='D2'>
                  D2 (bus osobowy, bus dostawczy, pickup)
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl required fullWidth variant='standard' size='small'>
              <InputLabel id='si-category-label'>
                Kategoria pojazdu w Słowenii
              </InputLabel>
              <Select
                labelId='si-category-label'
                id='si-category-select'
                label='Kategoria pojazdu w Słowenii'
                value={siCategory}
                onChange={handleSiCategoryChange}
              >
                <MenuItem value='2A'>2A (pojazd osobowy)</MenuItem>
                <MenuItem value='2B'>
                  2B (bus osobowy, bus dostawczy, pickup)
                </MenuItem>
              </Select>
            </FormControl>
            <DialogContentText>
              <Alert severity='info' sx={{ mt: 2 }}>
                Pola poniżej, tj. Kategoria pojazdu w Rumunii oraz Numer VIN
                pojazdu są nieobowiązkowe - uzupełnij je tylko wtedy jeśli
                wykupywane będą winiety do Rumunii.
              </Alert>
            </DialogContentText>
            <FormControl fullWidth variant='standard' size='small'>
              <InputLabel id='ro-category-label'>
                Kategoria pojazdu w Rumunii
              </InputLabel>
              <Select
                labelId='ro-category-label'
                id='ro-category-select'
                label='Kategoria pojazdu w Rumunii'
                value={roCategory}
                onChange={handleRoCategoryChange}
              >
                <MenuItem value='V1'>A (pojazd do przewozu osób)</MenuItem>
                <MenuItem value='V2'>B (pojazd do przewozu towarów)</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant='standard' size='small'>
              <TextField
                margin='dense'
                id='vehicle-vin-number-text-field'
                label='Numer VIN pojazdu'
                type='text'
                fullWidth
                variant='standard'
                size='small'
                value={vehicleVinNumber}
                onChange={handleVehicleVinNumberChange}
              />
            </FormControl>
            <DialogContentText>
              <Alert severity='info' sx={{ mt: 2 }}>
                Wykorzystaj kontrolkę poniżej, jeśli pojazd powinien zostać
                ukryty z zakładki Status bo np. został sprzedany i nie wchodzi
                już w skład floty.
              </Alert>
            </DialogContentText>
            <FormControl component='fieldset' variant='standard'>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isVehicleVisible}
                      onChange={handleIsVehicleVisibleChange}
                      name='isVehicleVisible'
                    />
                  }
                  label='Pokazuj pojazd na zakładce Status'
                />
              </FormGroup>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <FleetButton onClick={onClose}>Anuluj</FleetButton>
          <FleetButton
            onClick={handleSubmitButton}
            disabled={isSubmitButtonDisabled}
          >
            {state.vehicle ? 'Zaktualizuj' : 'Utwórz'}
          </FleetButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
