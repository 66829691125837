/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Plate from '../../../components/Plate';
import Flag from '../../../components/Flag/Flag';
import FleetTooltip from '../../../components/FleetTooltip';
import VehicleStatus from '../../../components/VehicleStatus';
import ExpirationChip from '../../../components/ExpirationChip';
import { useDevice } from '../../../hooks/device';
import { getCellStyleForMobile } from '../../../shared/styles';
import { lastRowWithoutBottomBorder } from '../../../shared/styles';
import { vignetteLabels, getDateLabel } from '../../../shared/labels';
import { VEHICLES_TABLE_COLUMN_WIDTHS } from '../../../shared/constants';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function StatusRow({ vehicle, isLastVehicle, onAddVignette }) {
  const { isMobile, isBrowser } = useDevice();

  const [open, setOpen] = useState(false);

  const getCellStyle = useCallback((style = {}) => ({
    ...getCellStyleForMobile(isBrowser),
    ...style,
  }));

  const renderAddToCartButton = (vehicle) => {
    const sx = {
      padding: 0,
      minWidth: 30,
      borderRadius: '3px',
      height: '100%',
    };

    return vehicle.isDynamic ? (
      <FleetTooltip
        placement='top'
        title='Aby wykupić winiety dla tego pojazdu, najpierw uzupełnij jego dane w zakładce Moja flota -> Pojazdy'
      >
        <div>
          <Button size='small' variant='outlined' sx={sx} disabled>
            <AddIcon />
          </Button>
        </div>
      </FleetTooltip>
    ) : (
      <Button
        size='small'
        variant='outlined'
        sx={sx}
        onClick={() => onAddVignette(vehicle)}
      >
        <AddIcon />
      </Button>
    );
  };

  const renderVignettesRows = () => {
    return vehicle.vignettes.map((vignette) => {
      return (
        <TableRow
          key={`${vignette.id}-${vignette.isExternal}`}
          sx={{
            opacity: vignette.isRegistered ? 1 : 0.4,
            ...lastRowWithoutBottomBorder,
          }}
        >
          {isBrowser ? <TableCell /> : null}
          <TableCell style={getCellStyle()}>
            {vignetteLabels[vignette.code]}
          </TableCell>
          {isBrowser ? (
            <TableCell style={getCellStyle()}>
              {getDateLabel(vignette.validSince)}
            </TableCell>
          ) : null}
          <TableCell style={getCellStyle()}>
            {getDateLabel(vignette.validUntil)}
          </TableCell>
          <TableCell style={getCellStyle()}>
            <div style={{ display: 'flex', gap: 5 }}>
              <ExpirationChip
                isRegistered={vignette.isRegistered}
                hasStarted={vignette.hasStarted}
                startsIn={vignette.startsIn}
                isStillValid={vignette.isStillValid}
                endsIn={vignette.endsIn}
              />
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  const getTableCellBorderBottom = () => {
    if (open) {
      return {};
    }

    if (isLastVehicle) {
      return { borderBottom: 0 };
    }

    return {};
  };

  return (
    <>
      <TableRow>
        <TableCell
          style={{
            width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_1,
            ...getTableCellBorderBottom(),
          }}
        >
          <IconButton size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          style={{
            width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_2,
            ...getTableCellBorderBottom(),
          }}
        >
          <Plate
            country={vehicle.registrationCountry}
            number={vehicle.registrationNumber}
          />
        </TableCell>
        <TableCell
          style={getTableCellBorderBottom()}
          colSpan={isMobile ? 1 : 2}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            {renderAddToCartButton(vehicle)}
            {vehicle.activeCountryCodes.map((activeCountryCode) => (
              <Flag key={activeCountryCode} countryCode={activeCountryCode} />
            ))}
          </div>
        </TableCell>
        <TableCell style={getTableCellBorderBottom()}>
          <VehicleStatus vignettes={vehicle.vignettes} />
        </TableCell>
      </TableRow>
      <StyledTableRow>
        <TableCell
          style={{
            ...(open ? null : { display: 'none' }),
            padding: 0,
          }}
          colSpan={isBrowser ? 5 : 4}
        >
          <Collapse in={open} timeout='auto' unmountOnExit>
            {vehicle.activeCountryCodes.length > 0 ? (
              <Box sx={{ margin: '10px 0px' }}>
                <Table size='small' style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      {isBrowser ? (
                        <TableCell
                          style={{
                            width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_1,
                          }}
                        />
                      ) : null}
                      <TableCell
                        style={{ width: VEHICLES_TABLE_COLUMN_WIDTHS.COLUMN_2 }}
                      >
                        Typ winiety
                      </TableCell>
                      {isBrowser ? <TableCell>Ważna od</TableCell> : null}
                      <TableCell>Ważna do</TableCell>
                      <TableCell>Wygasa</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderVignettesRows()}</TableBody>
                </Table>
              </Box>
            ) : (
              <Box
                sx={{
                  padding: 2,
                  textAlign: 'center',
                  fontStyle: 'italic',
                  color: 'rgb(99, 99, 99)',
                }}
              >
                Ten pojazd nie ma aktywnych winiet
              </Box>
            )}
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </>
  );
}
