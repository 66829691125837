/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import './MessagesTable.scss';

const displayDate = (createdAt) => {
  const date = new Date(createdAt);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    timeZone: 'Europe/Warsaw',
  };
  const polishDate = new Intl.DateTimeFormat('pl-PL', options).format(date);

  return polishDate;
};

export default function MessagesTable({ messages }) {
  return (
    <Box>
      {messages.map((m) => (
        <Card key={m.id} variant='outlined' style={{ width: '100%' }}>
          <Box
            style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box sx={{ p: 2 }}>
              <Typography variant='subtitle1' gutterBottom>
                <strong>{m.title}</strong>{' '}
                <span style={{ fontSize: 12 }}>{displayDate(m.createdAt)}</span>
              </Typography>
              <div className='MessagesTable__News__Content'>
                <div dangerouslySetInnerHTML={{ __html: m.content }} />
              </div>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );
}
