import React, { useEffect, useState } from 'react';
import { useCompany } from '../hooks/company';
import { useRepository } from '../hooks/api';
import VignettesTable from '../views/vignettes/VignettesTable/VignettesTable';
import Loader from '../components/Loader';

export default function Vignettes() {
  const company = useCompany();
  const repository = useRepository();

  const [vignettes, setVignettes] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const init = async () => {
    const vignettes = await repository.company.getVignettes(
      company.taxIdentificationHash
    );
    setVignettes(vignettes);
    setIsLoadingData(false);
  };

  useEffect(() => {
    init();
  }, []);

  return isLoadingData ? <Loader /> : <VignettesTable vignettes={vignettes} />;
}
