/* eslint-disable react/prop-types */
import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useVehiclesStateValue } from '../../../contexts/vehiclesStateContext';
import { useDevice } from '../../../hooks/device';
import TableBox from '../../../components/TableBox';
import ViewHeader from '../../../components/ViewHeader';
import FleetTooltip from '../../../components/FleetTooltip';
import FleetButton from '../../../components/FleetButton';
import VehicleRow from './VehicleRow';
import VehicleRowEmpty from './VehicleRowEmpty';
import VehicleDialog from './VehicleDialog';

export default function VehicleTable({ onVehiclesRefresh }) {
  const { isMobile, isBrowser } = useDevice();

  const [state, dispatch] = useVehiclesStateValue();

  const handleTextFilterChange = (newTextFilter) => {
    dispatch({
      type: 'SET_REGISTRATION_NUMBER_FILTER',
      payload: newTextFilter,
    });
    updateFilteredVehicles(newTextFilter);
  };

  const handleTextFilterReset = () => {
    dispatch({ type: 'SET_REGISTRATION_NUMBER_FILTER', payload: '' });
    updateFilteredVehicles('');
  };

  const updateFilteredVehicles = (_textFilter) => {
    let filteredVehiclesByText = state.vehicles;
    if (_textFilter.length > 0) {
      filteredVehiclesByText = filteredVehiclesByText.filter((vehicle) => {
        if (
          vehicle.registrationNumber
            .toLowerCase()
            .includes(_textFilter.toLowerCase())
        ) {
          return true;
        }

        return false;
      });
    }

    const filteredVehicles = [];
    for (const vehicle of state.vehicles) {
      const { id: vehicleId } = vehicle;
      const isVehicleInFilteredVehiclesByText = filteredVehiclesByText.find(
        ({ id }) => id === vehicleId
      );

      if (isVehicleInFilteredVehiclesByText) {
        filteredVehicles.push(vehicle);
      }
    }

    dispatch({ type: 'SET_FILTERED_VEHICLES', payload: filteredVehicles });
  };

  const handleVehicleDialogOpen = () => {
    dispatch({ type: 'SET_IS_VEHICLE_DIALOG_OPEN', payload: true });
  };

  const handleVehicleDialogClose = () => {
    dispatch({ type: 'SET_IS_VEHICLE_DIALOG_OPEN', payload: false });
    dispatch({ type: 'SET_VEHICLE', payload: null });
  };

  const handleVehicleDialogEdit = (vehicle) => {
    dispatch({ type: 'SET_VEHICLE', payload: vehicle });
    dispatch({ type: 'SET_IS_VEHICLE_DIALOG_OPEN', payload: true });
  };

  const renderTableBody = () => {
    if (state.filteredVehicles.length > 0) {
      return state.filteredVehicles.map((vehicle) => (
        <VehicleRow
          key={`${vehicle.registrationCountry}-${vehicle.registrationNumber}`}
          vehicle={vehicle}
          onVehiclesRefresh={onVehiclesRefresh}
          onVehicleEdit={handleVehicleDialogEdit}
        />
      ));
    }

    return <VehicleRowEmpty />;
  };

  return (
    <>
      <List style={{ flexGrow: '1', padding: 0 }}>
        <ViewHeader>
          <Typography
            variant='h6'
            style={{
              fontSize: 16,
            }}
          >
            Moja flota - pojazdy
          </Typography>
        </ViewHeader>
        <Alert severity='info'>
          Ta sekcja zawiera posortowane alfabetycznie informacje o wszystkich
          pojazdach, które wchodzą w skład floty. Pojazdy zmatowione (blade)
          zostały zczytane automatycznie na podstawie zamówień złożonych w
          przeszłości i nie mają uzupełnionych danych kategorii.{' '}
          <strong>
            Aby dla danego pojazdu odblokować możliwość wykupywania winiet,
            sprawdź tabelę poniżej i upewnij się, że dla tego pojazdu kolumny
            kategorii są wypełnione
          </strong>
          . Nastepnie przejdź do widoku Status, znajdź pojazd i dodaj winietę do
          koszyka.
        </Alert>
        <Card variant='outlined' sx={{ mt: 1, mb: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: isMobile ? 'column' : 'row',
              ...(isMobile
                ? {
                    pt: 1,
                    pr: 1,
                  }
                : {
                    pt: 2,
                    pr: 2,
                  }),
            }}
          >
            <Box sx={{ textAlign: 'right' }}>
              <FleetButton
                variant='contained'
                disableElevation
                onClick={handleVehicleDialogOpen}
              >
                Dodaj pojazd
              </FleetButton>
            </Box>
          </Box>
          <TableBox>
            <TableContainer>
              <Table style={{ tableLayout: 'fixed' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '30%' }}>Pojazd</TableCell>
                    {isBrowser && (
                      <>
                        <TableCell>Kat. Węgry</TableCell>
                        <TableCell>Kat. Słowenia</TableCell>
                        <TableCell>Kat. Rumunia</TableCell>
                        <TableCell>VIN</TableCell>
                      </>
                    )}
                    <TableCell>Widoczność</TableCell>
                    <TableCell>Akcje</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={isMobile ? 3 : 7}>
                      <div style={{ display: 'flex', gap: 5 }}>
                        <TextField
                          style={{ maxWidth: 140 }}
                          label='Filtruj'
                          variant='outlined'
                          size='small'
                          value={state.registrationNumberFilter}
                          onChange={(e) =>
                            handleTextFilterChange(e.target.value)
                          }
                        />
                        {isBrowser && (
                          <FleetTooltip
                            placement='top'
                            title='Usuń filtr po numerze rejestracyjnym'
                          >
                            <div>
                              <Button
                                size='small'
                                variant='outlined'
                                sx={{
                                  padding: 0,
                                  minWidth: 40,
                                  minHeight: 40,
                                  borderRadius: '3px',
                                }}
                                aria-haspopup='true'
                                disabled={
                                  state.registrationNumberFilter.length === 0
                                }
                                onClick={handleTextFilterReset}
                              >
                                <DeleteForeverIcon />
                              </Button>
                            </div>
                          </FleetTooltip>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  {renderTableBody()}
                </TableBody>
              </Table>
            </TableContainer>
          </TableBox>
        </Card>
      </List>
      {state.isVehicleDialogOpen && (
        <VehicleDialog
          onClose={handleVehicleDialogClose}
          onVehiclesRefresh={onVehiclesRefresh}
        />
      )}
    </>
  );
}
